<template>
  <section>
      <div class="content-header">
        <h2>Learning to love being a student</h2>
      </div>
      <div class="content-wrapper">
        <p>No matter where you teach or where your students are in their post-secondary journey, Toronto Metropolitan University’s <a href="https://tia.ryerson.ca/" target="_blank">Thriving in Action</a> blend of Positive Psychology and progressive learning strategies can help students harness willpower, mindfully manage time, study efficiently, bounce forward after setbacks, and so much more. </p>
        <p>Thriving in Action Online is an innovative resource to help students live with intention, learn with confidence, transform their relationship to school and experience sustainable success, however they define it. </p>
        <p>Through this website, you can learn the art and practice of…</p>
        <ul>
          <li class="pb-1">Appreciativeness</li>
          <li class="pb-1">Attentiveness</li>
          <li class="pb-1">Connectedness</li>
          <li class="pb-1">Deliberateness</li>
          <li class="pb-1">Determinedness</li>
          <li class="pb-1">Healthfulness</li>
          <li class="pb-1">Joyfulness</li>
          <li class="pb-1">Meaningfulness</li>
          <li class="pb-1">Open-heartedness</li>
          <li class="pb-1">Presentness</li>
          <li class="pb-1">Resourcefulness</li>
          <li class="pb-1">Skillfulness</li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
